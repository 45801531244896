import styled from 'styled-components';
import Colors from '../../Constants/Colors';

export const StoryBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 5rem;
    padding-bottom: 5rem;

    margin: auto;

    background-color: ${Colors.lightGrey};
`;