import Colors from "../../Constants/Colors";
import styled from 'styled-components';

type BorderedInputBoxProps = {
  text: string;
}

const StyledInput = styled.div`
  display: flex;
  height: 30px;
  width: 100%;

  border-style: solid;
  border-radius: 5px;
  border-color: ${Colors.blue};
  border-width: 1px;

  cursor: pointer;
`;

const TextWrapper = styled.div`
    display: flex;
    font-size: 12px;
    font-family: HelveticaLight;
    color: ${Colors.lightGrey};

    align-items: center;
    padding-left: 0.5rem;
`;

export function BorderedInputBox({text}: BorderedInputBoxProps) {

    return (
        <StyledInput>
            <TextWrapper>
                {text}
            </TextWrapper>
        </StyledInput>
    );
  }