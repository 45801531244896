import React from 'react';
import { BorderedButton } from '../Shared/BorderedButton';
import InsightCardIcon from '../../assets/InsightCardIcon.png';
import PlanCardIcon from '../../assets/PlanCardIcon.png';
import { HeaderText } from '../Shared/HeaderText';
import { DescriptionText } from '../Shared/DescriptionText';
import { CardWrapper, CardContentsWrapper, InsightIconWrapper, InsightIconBackground, TitleWrapper, DescriptionWrapper, CtaButtonWrapper } from './styles';

export type InsightPlanCardProps = {
    title: string,
    description: string,
    ctaButtonText: string,
    ctaButtonUrl: string,
    cardType: InsightPlanCardType
}

export enum InsightPlanCardType {
    Insight,
    Plan
}

export const InsightPlanCard = ({
    title,
    description,
    ctaButtonText,
    ctaButtonUrl,
    cardType
}: InsightPlanCardProps) => (
    <CardWrapper>
        <CardContentsWrapper>
            <InsightIconWrapper>
                <InsightIconBackground>
                    <img src={cardType == InsightPlanCardType.Insight ? InsightCardIcon : PlanCardIcon} alt={ctaButtonText}/>
                </InsightIconBackground>
            </InsightIconWrapper>
            <TitleWrapper>
                <HeaderText color="black">
                    {title}
                </HeaderText>
            </TitleWrapper>
            <DescriptionWrapper>
                <DescriptionText color="black">
                    {description}
                </DescriptionText>
            </DescriptionWrapper>
            <CtaButtonWrapper>
                <BorderedButton title={ctaButtonText} onClick={() => window.open(ctaButtonUrl)} />
            </CtaButtonWrapper>
        </CardContentsWrapper>
    </CardWrapper>
)


  