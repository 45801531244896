const blue = "#3DACD5";
const pink = "#E72076";
const grey = "#626262";
const lightGrey ="#E4E4E4"
const green = "#bada90";
const disabled = "rgb(155,155,155)";
const brownishGrey = "#626262";
const brownGrey = "#8b8b8b";
const iceBlue = "#f2f3f3";
const black = "#051217";
const hairlineGrey = "#051217";
const white = "#FFF";
const faintWhite = "#00000029";
const imageBackground = "rgb(212,212,212)";
const red = "#FF0000";
const orange = "#FFA500";
const usualImpactGreen = "#468D4B";
const minorImpactOrange = "#FFBB00";
const lightPink = "#FADAE8";

export default {
  lightGrey,
  blue,
  pink,
  grey,
  disabled,
  green,
  brownishGrey,
  brownGrey,
  iceBlue,
  black,
  imageBackground,
  hairlineGrey,
  white,
  faintWhite,
  red,
  orange,
  usualImpactGreen,
  minorImpactOrange,
  lightPink
};