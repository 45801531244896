import { CardDisplay } from './Modules/CardDisplay/CardDisplay';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import { GlobalFonts } from './Themes/fonts/fonts';
import styled from 'styled-components';
import { StoryBackground } from './Modules/Shared/StoryBackground';

/*GET <url> ? id=956e867f-68d0-ea11-a813-000d3a799417 &
  typename=mydna_feedcontentitem &
  type=10125 &
  orgname=org839e097b &
  OrgLCID=1033 &
  UserLCID=1033
*/

function App() {
  return (
    <div className="App">
    <GlobalFonts />
      <Router>
        <Switch>
            <Route exact path ="/">
              <StoryBackground>
                <CardDisplay />
              </StoryBackground>
            </Route>
          </Switch>
        </Router>
    </div>
  );
}

export default App;