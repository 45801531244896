import VideoPlayIcon from '../../assets/VideoPlayIcon.png';
import { CardWrapper, CardContentsWrapper, PlayIconWrapper, VideoPlayIconComponent, HeaderTextWrapper } from './styles';
import { HeaderText } from '../Shared/HeaderText';
import { GradedBackgroundImage } from '../Shared/GradedBackgroundImage';
import Truncate from 'react-truncate';

export type VideoCardProps = {
    imageSourceUrl: string,
    videoSourceUrl: string,
    title: string
}

export const VideoCard = ({
    imageSourceUrl,
    videoSourceUrl,
    title
}: VideoCardProps) => (
    <CardWrapper>
        <a href={videoSourceUrl} target="_blank">
            <GradedBackgroundImage src={imageSourceUrl} gradientOpacity={0.8}>
                <CardContentsWrapper>
                    <PlayIconWrapper>
                        <VideoPlayIconComponent src={VideoPlayIcon} />
                    </PlayIconWrapper>
                    <HeaderTextWrapper>
                        <HeaderText color="white">
                            <Truncate lines={3} ellipsis={"..."} trimWhitespace>
                                {title}
                            </Truncate>
                        </HeaderText>
                    </HeaderTextWrapper>
                </CardContentsWrapper>
            </GradedBackgroundImage>
        </a>
    </CardWrapper>
)


  