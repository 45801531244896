import React from 'react';
import { HeaderText } from '../Shared/HeaderText';
import { CardWrapper, CardContentsWrapper, ContentWrapper, DescriptionText, InputWrapper, ButtonWrapper, HalfSizeInputWrapper } from './styles';
import { BorderedButton } from '../Shared/BorderedButton';
import Colors from '../../Constants/Colors';
import { BorderedInputBox } from '../Shared/BorderedInputBox';

type InteractiveUpdateUserProfileCardProps = {
    onClick: () => void;
}

export const InteractiveUpdateUserProfileCard = ({ onClick }: InteractiveUpdateUserProfileCardProps) => (
    <CardWrapper>
        <CardContentsWrapper>
            <ContentWrapper>
                <HeaderText color="black">How tall are you?</HeaderText>
            </ContentWrapper>
            <ContentWrapper>
                <DescriptionText color="black">
                    Your DNA isn't the only way we personalize your plans. Your height plays a role
                    in determining your daily calorie expenditure, in line with your goals.
                </DescriptionText>
            </ContentWrapper>
            <InputWrapper>
                <HalfSizeInputWrapper>
                    <BorderedInputBox text="Feet"/>
                </HalfSizeInputWrapper>
                <HalfSizeInputWrapper>
                    <BorderedInputBox text="Inch"/>
                </HalfSizeInputWrapper>
            </InputWrapper>
            <ContentWrapper>
                <ButtonWrapper>
                    <BorderedButton title="Save" onClick={onClick} backgroundColor={Colors.blue} textColor={Colors.white} />
                </ButtonWrapper>
            </ContentWrapper>
        </CardContentsWrapper>
    </CardWrapper>
)