import { ReactNode } from 'react';
import styled from 'styled-components';

type ErrorTextProps = {
    children: ReactNode
}

const ErrorTextWrapper = styled.div`
    color: red;
    font-family: NowBlack;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
`;

export const ErrorText = ({children} : ErrorTextProps) => {
    return (
        <ErrorTextWrapper>
            {children}
        </ErrorTextWrapper>
    )
}