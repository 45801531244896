import { createGlobalStyle } from 'styled-components';

import HelveticaBold from './helvetica/Helvetica-Bold.ttf';
import HelveticaLight from './helvetica/Helvetica-Light.ttf';
import Helvetica from './helvetica/Helvetica.ttf';

import NowBlack from './now/Now-Black.otf';
import NowMedium from './now/Now-Medium.otf';
import NowRegular from './now/Now-Regular.otf';

import SpaceMono from './spaceMono/SpaceMono-Regular.ttf';

export const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'HelveticaBold';
        src: local('Helvetica Bold'), local('HelveticaBold'),
        url(${HelveticaBold}) format('truetype')
    }

    @font-face {
        font-family: 'HelveticaLight';
        src: local('HelveticaLight'),
        url(${HelveticaLight}) format('truetype')
    }

    @font-face {
        font-family: 'Helvetica';
        src: local('Helvetica'),
        url(${Helvetica}) format('truetype')
    }

    @font-face {
        font-family: 'NowBlack';
        src: local('Now Black'), local('NowBlack'),
        url(${NowBlack}) format('OpenType')
    }

    @font-face {
        font-family: 'NowMedium';
        src: local('Now Medium'), local('NowMedium'),
        url(${NowMedium}) format('OpenType')
    }

    @font-face {
        font-family: 'NowRegular';
        src: local('Now Regular'), local('NowRegular'),
        url(${NowRegular}) format('OpenType')
    }

    @font-face {
        font-family: 'SpaceMono';
        src: local('SpaceMono'), local('SpaceMono'),
        url(${SpaceMono}) format('truetype')
    }
`;