import styled from 'styled-components';
import Colors from '../../Constants/Colors';
import { CardDimensions } from '../../Constants/CardDimensions';

export const CardWrapper = styled.div`
display: flex;

width: ${CardDimensions.width.samsungGalaxyS9};
max-height: ${CardDimensions.height.Regular};

padding-bottom: 1rem;

background-color: white;

border-radius: 10px;
border-width: 1px;
overflow: hidden;
`;

export const CardContentsWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 0.5rem;
`;

export const InsightIconWrapper = styled.div`
display: flex;
padding: 0.5rem;
height: 30%;

justify-content: center;
align-items: center;
`;

export const InsightIconBackground = styled.div`
display: flex;
height: 4.5rem;
width: 4.5rem;
border-radius: 50%;

background-color: ${Colors.pink};

justify-content: center;
align-items: center;

margin: auto;
`;

export const TitleWrapper = styled.div`
padding: 0.5rem 1rem;
height: 25%;
text-align: center;
`;

export const DescriptionWrapper = styled.div`
padding: 0.5rem 1rem;
height: 25%;
text-align: center;
`;  

export const CtaButtonWrapper = styled.div`
display: flex;
padding-top: 0.5rem;
padding-left: 1rem;
padding-right: 1rem;

height: 20%;
justify-content: center;
align-items: center;
`;