import Colors from "../../Constants/Colors";
import styled from 'styled-components';

type BorderedButtonProps = {
  title: string;
  onClick: () => void;
  backgroundColor?: string;
  textColor?: string;
}

const StyledButton = styled.input<{
  backgroundColor: string,
  textColor: string,
  borderColor: string
}>`
  display: flex;
  height: 30px;
  width: 100%;
  border-radius: 20px;
  border-color: ${(props) => props.borderColor};
  border-width: 2px;

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};

  text-transform: uppercase;
  font-family: helveticabold;
  font-size: 14px;
  text-align: center;

  padding-left: 15px;
  padding-right: 15px;

  cursor: pointer;
`;

export function BorderedButton({
    title,
    backgroundColor,
    textColor,
    onClick
  }: BorderedButtonProps) {

    let borderColor = Colors.blue;

    return (
      <StyledButton
        backgroundColor={backgroundColor ?? "transparent"}
        textColor={textColor ?? Colors.blue}
        borderColor={borderColor}
        value={title}
        onClick={onClick}
      />
    );
  }