import React from 'react';
import { HeaderText } from '../Shared/HeaderText';
import { GradedBackgroundImage } from '../Shared/GradedBackgroundImage';
import { BorderedButton } from '../Shared/BorderedButton';
import { CardContentsWrapper, CardWrapper, CtaWrapper, DescriptionWrapper, HeaderTextWrapper, BorderedButtonSizeWrapper } from './styles';
import { DescriptionText } from '../Shared/DescriptionText';

export type ArticleCardProps = {
    imageSourceUrl: string,
    articleUrl: string,
    title: string,
    description: string,
    ctaText: string
}

export const ArticleCard = ({
    imageSourceUrl,
    articleUrl,
    title,
    description,
    ctaText
}: ArticleCardProps) => (
    <CardWrapper>
        <GradedBackgroundImage src={imageSourceUrl} gradientOpacity={1}>
            <CardContentsWrapper>
                <HeaderTextWrapper>
                    <HeaderText color="white">
                        {title}
                    </HeaderText>
                </HeaderTextWrapper>
                <DescriptionWrapper>
                    <DescriptionText color="white">
                        {description}
                    </DescriptionText>
                </DescriptionWrapper>
                <CtaWrapper>
                    <BorderedButtonSizeWrapper>
                        <BorderedButton title={ctaText ?? "Read more"} onClick={() => window.open(articleUrl)} />
                    </BorderedButtonSizeWrapper>
                </CtaWrapper>
            </CardContentsWrapper>
        </GradedBackgroundImage>
    </CardWrapper>
)