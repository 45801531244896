import styled from "styled-components";
import { CardDimensions } from '../../Constants/CardDimensions';

export const CardWrapper = styled.div`
    display: flex;

    width: ${CardDimensions.width.samsungGalaxyS9};
    height: ${CardDimensions.height.Regular};

    border-radius: 10px;
    border-width: 1px;
    overflow: hidden;
`;

export const CardContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    padding: 1rem;
`;

export const HeaderTextWrapper = styled.div`
    display: flex;  
    height: 55%;
    padding-bottom: 1rem;

    align-items: flex-end;
`;

export const DescriptionWrapper = styled.div`
    display: flex;

    font-family: NowRegular;
`;

export const CtaWrapper = styled.div`
    padding-top: 1rem;
`;

export const BorderedButtonSizeWrapper = styled.div`
    display: flex;
    width: 125px;
`;