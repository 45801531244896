import React from 'react';
import { HeaderText } from '../Shared/HeaderText';
import { CardWrapper, CardContentsWrapper, ContentWrapper, DescriptionText, InputWrapper, InputSubtextWrapper, ButtonWrapper } from './styles';
import { BorderedInputBox } from '../Shared/BorderedInputBox';
import { BorderedButton } from '../Shared/BorderedButton';
import Colors from '../../Constants/Colors';

type InteractiveActivateKitBarcodeCardProps = {
    onClick: () => void;
}

export const InteractiveActivateKitBarcodeCard = ({ onClick }: InteractiveActivateKitBarcodeCardProps) => (
    <CardWrapper>
        <CardContentsWrapper>
            <ContentWrapper>
                <HeaderText color="black">Activate your DNA kit</HeaderText>
            </ContentWrapper>
            <ContentWrapper>
                <DescriptionText color="black">
                    We’re eagerly waiting for your DNA sample to arrive at the lab, but to
                    make sure we know it’s yours you first need to activate your kit.
                </DescriptionText>
            </ContentWrapper>
            <InputWrapper>
                <BorderedInputBox text="Enter your barcode number"/>
                <InputSubtextWrapper>
                    I can't find my barcode number
                </InputSubtextWrapper>
            </InputWrapper>
            <ContentWrapper>
                <ButtonWrapper>
                    <BorderedButton title="Activate" onClick={onClick} backgroundColor={Colors.blue} textColor={Colors.white} />
                </ButtonWrapper>
            </ContentWrapper>
        </CardContentsWrapper>
    </CardWrapper>
)