import styled from "styled-components";
import { CardDimensions } from '../../Constants/CardDimensions';
import Colors from "../../Constants/Colors";

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: ${CardDimensions.width.samsungGalaxyS9};

    background-color: white;

    border-radius: 10px;
    border-width: 1px;
    overflow: hidden;
`;

export const CardContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
`;

export const ContentWrapper = styled.div`
    padding-bottom: 1rem;
`;

export const DescriptionText = styled.span<{color: string}>`
    display: flex;
    width: 100%;
    font-family: HelveticaLight;
    color: ${(props) => props.color};
    font-size: 14px;
    line-height: 25px;
`;

export const HeaderTextWrapper = styled.div`
    display: flex;  
    height: 55%;
    padding-bottom: 1rem;

    align-items: flex-end;
`;

export const DescriptionWrapper = styled.div`
    display: flex;

    font-family: NowRegular;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputSubtextWrapper = styled.div`
    padding-top: 0.5rem;

    font-family: HelveticaBold;
    font-size: 12px;
    font-weight: bold;
    color: ${Colors.blue};
`;

export const ButtonWrapper = styled.div`
    display: flex;

    width: 8rem;

    padding-top: 1rem;
`;