import React from 'react';
import axios from 'axios';
import { getFeedContentItem } from '../../graphql/queries';
import { FeedContentItem } from '../../schema';
import { VideoCard } from '../VideoCard/VideoCard';
import { ArticleCard } from '../ArticleCard/ArticleCard';
import { InsightPlanCard, InsightPlanCardType } from '../InsightPlanCard/InsightPlanCard';
import { ErrorText } from '../Shared/ErrorText';
import { GeneralCard } from '../GeneralCard/GeneralCard';
import { InteractiveActivateKitBarcodeCard } from '../InteractiveActivateKitBarcodeCard/InteractiveActivateKitBarcodeCard';
import { InteractiveUpdateUserProfileCard } from '../InteractiveUpdateUserProfileCard/InteractiveUpdateUserProfileCard';

type ErrorCardProps = {
    message: string
}

const ErrorCard = (props: ErrorCardProps) => {
    return (
    <GeneralCard>
        <ErrorText>
            {props.message}
        </ErrorText>
    </GeneralCard>);
};

export const CardDisplay = () => {
    
    const [feedContentItem, setFeedContentItem] = React.useState<FeedContentItem>();
    const params = GetParameterArray();
    const id = params.find(x => x.key === "id")?.value;
    const [isLoading, setLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
        async function GetFeedContentItem(id: string){
    
            const variables = { 'id': id };

            let request = JSON.stringify({
                query: getFeedContentItem,
                variables: variables
            });
        
            let headers = { 'x-api-key': process.env.REACT_APP_APPSYNC_APIKEY };
        
            const response = await axios.post(
                process.env.REACT_APP_APPSYNC_APIURL!, 
                request, 
                { headers: headers }
            );

            if (response.status != 200) throw "An error occured whilst setting program";
            setFeedContentItem(response.data?.data?.getFeedContentItem.data[0]);
        }
        
        GetFeedContentItem(id!).then(() => setLoading(false));
    }, [id]);

    if (!id) return <ErrorCard message="NO URL PARAMETER FOR FEED CONTENT ITEM ID" />;
    if (isLoading) return <h1>LOADING</h1>;
    if (!feedContentItem) return <ErrorCard message="COULD NOT FIND FEED CONTENT ITEM FOR THAT ID" />;

    var mockButtonClick = () => {
        console.log("Mock button click!")
    };

    if (feedContentItem) {
        switch(feedContentItem.cardType) {
            case "VideoCard":
                return <VideoCard
                    title = {feedContentItem.title!}
                    imageSourceUrl = {feedContentItem.imageUrl!}
                    videoSourceUrl = {feedContentItem.videoUrl!}
                />
            case "ArticleCard":
                return <ArticleCard
                    title = {feedContentItem.title!}
                    description = {feedContentItem.description}
                    ctaText = {feedContentItem.callToActionCaption!}
                    articleUrl = {feedContentItem.articleUrl!}
                    imageSourceUrl = {feedContentItem.imageUrl!}
                />
            case "InsightsCard":
                return <InsightPlanCard
                    cardType = {InsightPlanCardType.Insight}
                    title = {feedContentItem.title!}
                    description = {feedContentItem.description!}
                    ctaButtonText = {feedContentItem.callToActionCaption!}
                    ctaButtonUrl = {feedContentItem.callToActionDeepLinkUrl!}
                />
            case "PlansCard":
                return <InsightPlanCard
                    cardType = {InsightPlanCardType.Plan}
                    title = {feedContentItem.title!}
                    description = {feedContentItem.description!}
                    ctaButtonText = {feedContentItem.callToActionCaption!}
                    ctaButtonUrl = {feedContentItem.callToActionDeepLinkUrl!}
                />
            case "InteractiveActivateKitBarcodeCard":
                return <InteractiveActivateKitBarcodeCard onClick = {mockButtonClick}/>
            case "InteractiveUpdateUserProfileCard":
                return <InteractiveUpdateUserProfileCard onClick = {mockButtonClick}/>
            default:
                return <ErrorCard message={`No card found for type ${feedContentItem.cardType}`} />;
        }
    }

    return null;
}

const GetParameterArray = () => {
    // Get parameter string from window
    var params = window.location.search;
    // Strip off leading ?
    params = params.substring(1);
    // Split on &
    var paramArray = params.split("&");
    // Create dict where <key> = <value>
    var paramDict: { key: string; value: string; }[] = [];

    paramArray.forEach(param => {
        let splitParam = param.split("=");
        paramDict.push({
            key: splitParam[0],
            value: splitParam[1]
        })
    });

    return paramDict;
}