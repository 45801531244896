import {BodyText} from "../Shared/BodyText";
import { ReactNode } from "react";
import { CardBorder, MessageWrapper} from './styles';

type GeneralCardProps = {
    children: ReactNode
}

export const GeneralCard = ({ children }: GeneralCardProps) => (
    <CardBorder>
        <MessageWrapper>
            <BodyText>
                {children}
            </BodyText>
        </MessageWrapper>
    </CardBorder>
)
