import styled from 'styled-components';

export const ComponentButtonsWrapper = styled.div`
   display: inline-block;
   margin: auto;

   width: 100%;
   length: 100%;

   max-width: 21rem;
`;

export const CardBorder = styled.div`
height: 300px;
width: 300px;
border: 2px solid black;
border-radius: 5px;

display: flex;
`;

export const MessageWrapper = styled.div`
display: flex;
height: 100%;
width: 100%;

justify-content: center;
align-items: center;
text-align: center;
`;