/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFeedContentItem = /* GraphQL */ `
  query GetFeedContentItem($id: ID!) {
    getFeedContentItem(id: $id) {
      data {
        active
        articleUrl
        callToActionCaption
        callToActionDeepLinkUrl
        cardType
        description
        feedContentId
        imageUrl
        language
        name
        partnerImageUrlLarge
        partnerImageUrlSmall
        title
        videoUrl
      }
    }
  }
`;
