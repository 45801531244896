import styled from 'styled-components';
import { CardDimensions } from '../../Constants/CardDimensions';

export const CardWrapper = styled.div`
    display: flex;

    width: ${CardDimensions.width.samsungGalaxyS9};
    height: ${CardDimensions.height.Short};

    border-radius: 10px;
    border-width: 1px;
    overflow: hidden;
`;

export const CardContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
`;

export const PlayIconWrapper = styled.div`
    display: flex;
    height: 66%;
    align-items: flex-end;
    justify-content: center;
`;

export const HeaderTextWrapper = styled.div`
    padding: 1rem;
`;

export const VideoPlayIconComponent = styled.img`
    height: 4rem;
    width: 4rem;
    margin-bottom: 1rem;
`;